<template>
  <v-container grid-list-md>
    <v-card style="margin-bottom:5em">
      <v-card-title>Study Abroad Application</v-card-title>
      <portal-form
        v-if="isLoaded"
        :info-card="revision.infoCard"
        :info-card-text="revision.infoCardText"
        :sections="revision.sections"
        :section-type="revision.sectionType"
        :init-form-data="initFormData"
        title=""
        @updatedField="update"
        @submit="saveSubmission">
        <template v-slot:afterButtons>
          <v-spacer></v-spacer>
          <v-btn text @click="finishLater">Save and Finish Later</v-btn>
        </template>
      </portal-form>
      <v-alert v-else type="info" outlined>Loading your Study Abroad application. This should take just a few seconds...</v-alert>
    </v-card>
  </v-container>
</template>
<style>
.v-stepper__step__step .v-icon.v-icon {
  font-size: 1rem
}
</style>
<script>
import { ref, computed, onMounted } from '@vue/composition-api'

export default {
  components: {
    PortalForm: () => import('@/components/forms/Form')
  },
  setup (props, { root }) {
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const id = computed(() => root.$route.params.id)
    const formId = ref('')
    const initFormData = ref({})
    const dataArr = ref([])
    const isLoaded = ref(false)
    const core = ref([])

    const service = root.$feathers.service('forms/submission')

    const revision = ref({})
    onMounted(async () => {
      const form = await root.$feathers.service('forms/base').get('60f1cdb2ec295937a80ac90c')
      const revData = await root.$feathers.service('forms/revision').get(form.revisions.active)

      const saData = await root.$feathers.service('student/study-abroad').get(id.value)
      formId.value = saData.formSubmission
      const sub = await service.get(formId.value)
      dataArr.value = sub.dataArr
      const obj = {}
      for (const { field, value } of sub.dataArr) {
        if (field === 'Emergency Contacts') console.log(value)
        if (value !== '') {
          obj[field] = value
        }
      }
      console.log(JSON.parse(JSON.stringify(obj)))
      initFormData.value = obj
      // Find the "core" fields and add them to the core array
      // These are the fields that are also stored in the Study Abroad object
      // We need to know what these fields are so we can update them as changes are made to them
      // This includes term, major, grad year, partner, and location fields (courses will be copied upon submission)
      const coreHash = { programTerm: '', majors: '', gradYear: '', partner: '', location: '', courses: '' }
      for (let i = 0; i < revData.sections[0].inputs.length; i++) {
        const { label, input } = revData.sections[0].inputs[i]
        if (input === 'html-block') continue
        // These should auto-populate with the user's information, so we are going to make them readonly
        if (label === 'Name' || label === 'Banner ID') {
          revData.sections[0].inputs.splice(i, 1, { ...revData.sections[0].inputs[i], readonly: true })
        } else if (label.search(/grad/ig) >= 0 && label.search(/year/ig) >= 0 && coreHash.gradYear === '') {
          coreHash.gradYear = label
        } else if (label.search(/major/ig) >= 0 && coreHash.majors === '') {
          coreHash.majors = label
        } else if (label.search(/term/ig) >= 0 && coreHash.term === '') {
          coreHash.programTerm = label
        } else if (label.search(/partner/ig) >= 0 && coreHash.partner === '') {
          coreHash.partner = label
        } else if (label.search(/location/ig) >= 0 && coreHash.location === '') {
          coreHash.location = label
        } else if (label.search(/course/ig) >= 0 && coreHash.courses === '') {
          coreHash.courses = label
        }
        // Once we hve all of the core fields we don't need to keep looking at the other fields
        let isComplete = true
        for (const field in coreHash) {
          if (coreHash[field] === '') {
            isComplete = false
            break
          }
        }
        if (isComplete) {
          break
        }
      }
      for (const field in coreHash) {
        core.value.push({ field, label: coreHash[field] })
      }
      revision.value = revData
      setTimeout(() => { isLoaded.value = true }, 500)
    })

    async function update ({ field, value, index, action }) {
      if (!isLoaded.value) return
      let rec = dataArr.value.filter(({ field: arrField }) => arrField === field)
      if (rec.length === 0) {
        // Push the value into the data array
        if (index != null) {
          const temp = []
          while (temp.length < index) temp.push({})
          temp.push(value)
          dataArr.value.push({ field, value: temp })
        } else {
          dataArr.value.push({ field, value })
        }
        rec = dataArr.value.filter(({ field: arrField }) => arrField === field)
      } else if (index != null) {
        if (Array.isArray(rec[0].value)) {
          if (action === 'remove') {
            rec[0].value.splice(index, 1)
          } else if (action === 'add') {
            if (typeof (value) === 'object') rec[0].value.push({ ...value })
            else rec[0].value.push(value)
          } else if (JSON.stringify(rec[0].value[index]) !== JSON.stringify(value)) {
            if (typeof (value) === 'object') rec[0].value.splice(index, 1, { ...value })
            else rec[0].value.splice(index, 1, value)
          }
        }
      } else if (JSON.stringify(rec[0].value) !== JSON.stringify(value)) {
        rec[0].value = value
      }
      await service.patch(formId.value, { dataArr: dataArr.value })
      // Check to see if this is a piece of "core" data (i.e. data that is also stored in the study abroad collection); if so, update the study abroad collection with the updated field
      const temp = core.value.filter(({ label }) => label === field)
      if (temp.length > 0) {
        if (temp[0].field === 'courses') {
          // Need to map the value array fields to match what is in the study abroad system
          const temp = rec[0].value.map((row) => {
            const obj = {}
            for (const field in row) {
              if (field.search(/name/ig) >= 0) obj.name = row[field]
              else if (field.search(/credit/ig) >= 0) obj.credits = row[field]
              else if (field.search(/equivalent/ig) >= 0) obj.equivalent = row[field]
            }
            return obj
          })
          await root.$feathers.service('student/study-abroad').patch(id.value, { courses: temp })
        } else {
          await root.$feathers.service('student/study-abroad').patch(id.value, { [temp[0].field]: rec[0].value })
        }
      }
    }

    const submitted = ref(false)
    const isSubmitting = ref(false)
    async function saveSubmission () {
      // const advisor = {}
      const [{ value: advisorUsername }] = dataArr.value.filter(({ field }) => field === 'Academic Advisor')
      const { data } = await root.$feathers.service('directory/people').find({ query: { ldap: advisorUsername } })
      const name = (data.length > 0 ? data[0].name.first + ' ' + data[0].name.last : advisorUsername)
      // Need to add the approvers here along with email notifications to those who need to approve
      const approvals = [
        {
          department: 'Advisor',
          approved: false,
          name,
          username: advisorUsername
        },
        {
          department: 'Records',
          approved: false
        },
        {
          department: 'Financial Aid',
          approved: false
        },
        {
          department: 'Student Development',
          approved: false
        },
        {
          department: 'Health Services',
          approved: false
        },
        {
          department: 'PriestHill Counseling Services',
          approved: false
        },
        {
          department: 'World Languages',
          approved: false
        }
      ]
      await root.$feathers.service('student/study-abroad').patch(id.value, { status: 'Pending', approvals })
      await service.patch(formId.value, { status: 'Submitted' })
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'Your study-abroad application has been submitted.' })
      root.$router.push('/student/study-abroad')
    }

    function finishLater () {
      root.$store.dispatch('main/snackbar', { color: 'success', text: 'Your study-abroad application has been saved. You can return to this page to complete it any time.' })
      root.$router.push('/student/study-abroad')
    }

    return {
      user,
      id,
      initFormData,
      dataArr,
      isLoaded,
      core,
      revision,
      update,
      submitted,
      isSubmitting,
      saveSubmission,
      finishLater
    }
  }
}
</script>
